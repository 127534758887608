import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"

import SEO from "../components/SEO"
import ParagraphList from "../components/ParagraphList"
import Examples from "../components/Examples"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const AboutPage = ({ intl }) => {
  const rawData = useStaticQuery(graphql`
    {
      nl: allMarkdownRemark(
        filter: {
          frontmatter: {
            type: { eq: "paragraph" }
            parent_id: { eq: "asbest" }
            langKey: { eq: "nl" }
          }
        }
        sort: { fields: frontmatter___weight }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 604, maxHeight: 593) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      fr: allMarkdownRemark(
        filter: {
          frontmatter: {
            type: { eq: "paragraph" }
            parent_id: { eq: "asbest" }
            langKey: { eq: "fr" }
          }
        }
        sort: { fields: frontmatter___weight }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 604, maxHeight: 593) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = rawData[intl.locale]
  const { edges: paragraphs } = data

  return (
    <>
      <SEO title="Asbest" />
      <Layout>
        <div className="c-page-wrapper c-page-wrapper--flush">
          <Link
            to="/activiteiten"
            className="c-button c-button--brand c-button--top-right"
          >
            <FormattedMessage id="view_services" />

            <svg
              className="c-button__icon"
              width="18"
              height="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 8.214V6.286h14.786v1.928z" fill="#FFF" />
              <path d="M11.28 14l-1.637-1.637 6.72-6.72L18 7.28z" fill="#FFF" />
              <path d="M18 7.22l-1.637 1.637-6.72-6.72L11.28.5z" fill="#FFF" />
            </svg>
          </Link>
          <ParagraphList data={paragraphs} />
          <Examples />
        </div>
      </Layout>
    </>
  )
}

export default injectIntl(AboutPage)
